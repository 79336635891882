import medal1 from "./icons/medal.png";
import second from "./icons/second.png";
import award from "./icons/award.png";
import third from "./icons/third.png";
import fischer from "./icons/fischer.png";
import bulletbill from "./icons/bulletbill.png";
import shark from "./icons/shark.png";
import pilopp from "./icons/top-right.png";
import ti from "./icons/10.png";
import tjue from "./icons/20.png";
import tretti from "./icons/30.png";
import femti from "./icons/50.png";
import hundre from "./icons/100.png";
import slingshot from "./icons/slingshot.png";
import boxer from "./icons/boxer.png";
import gm from "./icons/crown.png";
import im from "./icons/quality.png";
import fm from "./icons/radio.png";
import cm from "./icons/cm.png";
import arm from "./icons/robot-arm.png";
import king from "./icons/king.png";
import gepard from "./icons/cheetah.png";
import orangutan from "./icons/orangutan.png";
import gg from "./icons/gg.png";
import rogue from "./icons/rogue.png";
import knightmate from "./icons/knightmate.png";
import bishopknight from "./icons/bishopknight.png";
import point from "./icons/point.png";
import strat from "./icons/strat.png";
import hugh from "./icons/hugh.png";
import horse from "./icons/horse.png";
import sangria from "./icons/sangria.png";
import queen from "./icons/queen.png";
import baby from "./icons/baby.png";
import gettingthere from "./icons/gettingthere.png";
import growing from "./icons/growing.png";
import talking from "./icons/chat.png";
import cross from "./icons/cross.png";
import keeper from "./icons/keeper.png";
import gandalf from "./icons/gandalf.png";
import pusheen from "./icons/pusheen.png";
import two from "./icons/two.png";
import three from "./icons/three.png";
import four from "./icons/four.png";
import five from "./icons/five.png";
import stroller from "./icons/stroller.png";
import bent from "./icons/bent.png";
import walk from "./icons/walk.png";
import walk_flipped from "./icons/walk_flipped.png";
import twins from "./icons/twins.png";
import sword from "./icons/sword.png";
import speed from "./icons/speed.png";
import risk from "./icons/caution.png";
import danger from "./icons/skull.png";
import gaussian from "./icons/gaussian.png";
import berserk_win from "./icons/berserk_win.png";
import runner from "./icons/runner.png";
import recovery from "./icons/recovery.png";
import wim from "./icons/princess.png";
import wfm from "./icons/medal.png";
import stone from "./icons/stone.png";
import granite from "./icons/granite.png";
import diamond from "./icons/diamond.png";
import alarm from "./icons/alarm.png";
import zombie from "./icons/zombie.png";
import strong from "./icons/strong.png";
import stronger from "./icons/stronger.png";
import strongest from "./icons/strongest.png";

export default [
  {
    title: "STARTED",
    image: medal1,
    description: "Play one tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length;
    },
    xp: 10,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "GOLD",
    image: medal1,
    description: "Win a tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "SILVER",
    image: second,
    description: "Finish second",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.secondPlaces;
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BRONZE",
    image: third,
    description: "Finish third",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.thirdPlaces;
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "MASTER",
    image: award,
    description: "Finish among the top 3 in 5 tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      const pallPlasseringer =
        player.firstPlaces + player.secondPlaces + player.thirdPlaces;
      return pallPlasseringer > 4;
    },
    xp: 1000,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "UNDERDOG",
    image: slingshot,
    description: "Beat a higher rated player",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.beaten_higher_rated;
    },
    xp: 50,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "SHARK",
    image: shark,
    description: "Win a Blitz tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.filter(
        (t) => t.key === "blitz" && t.rank === 1
      ).length;
    },
    xp: 1000,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BULLET BILL",
    image: bulletbill,
    description: "Win a Bullet tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "bullet" && t.rank === 1
      );
    },
    xp: 1000,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "PERFORMANCE",
    image: pilopp,
    description: "Perform higher than your rating in a tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.performance - t.rating > 0);
    },
    xp: 250,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "TEN",
    image: ti,
    description: "Get at least 10 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 9);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "TWENTY",
    image: tjue,
    description: "Get at least 20 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 19);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "THIRTY",
    image: tretti,
    description: "Get at least 30 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 29);
    },
    xp: 90,
    lvlReq: 2,
    permanent: true,
  },
  {
    title: "FIFTY",
    image: femti,
    description: "Get at least 50 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 49);
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "HUNDRED",
    image: hundre,
    description: "Get at least 100 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 99);
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "TITLEBREAKER",
    image: boxer,
    description: "Beat a titled player.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_titled;
    },
    xp: 800,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BERSERK",
    image: sword,
    description: "Berserk a game",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.berserk;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "BERSERK WIN",
    image: berserk_win,
    description: "Berserk a game and win",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.berserk_win;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "USAIN BOLT",
    image: runner,
    description: "Checkmate in 10 seconds or less",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.fastest_checkmate < 11;
    },
    xp: 958,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "KING",
    image: gm,
    description: "Beat a GM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_gm;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "PRINCE",
    image: im,
    description: "Beat an IM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_im;
    },
    lvlReq: 7,
    xp: 1000,
    permanent: true,
  },
  {
    title: "DAB",
    image: fm,
    description: "Beat an FM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_fm;
    },
    lvlReq: 5,
    xp: 800,
    permanent: true,
  },
  {
    title: "CM BEATER",
    image: cm,
    description: "Beat a CM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_cm;
    },
    lvlReq: 3,
    xp: 400,
    permanent: true,
  },
  {
    title: "WOW",
    image: arm,
    description: "Beat a player rated at least 300 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_300_higher_rated;
    },
    lvlReq: 5,
    xp: 300,
    permanent: true,
  },
  {
    title: "CHEETAH",
    image: gepard,
    description: "Play 40+ games in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.flest_partier_paa_en_turnering > 39;
    },
    lvlReq: 5,
    xp: 1000,
    permanent: true,
  },
  {
    title: "IMPOSSIBLE",
    image: king,
    description: "Beat Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_magnus;
    },
    lvlReq: 10,
    xp: 2001,
    permanent: true,
  },
  {
    title: "PLAYER",
    image: rogue,
    description: "Play 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 99;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "KNIGHTMARE",
    image: knightmate,
    description: "Promote to knight with mate",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.wowowK;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "TEAMWORK",
    image: bishopknight,
    description: "Mate with bishop and knight",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.mate_with_bishop_and_knight;
    },
    lvlReq: 7,
    xp: 1200,
    permanent: true,
  },
  {
    title: "TO THE POINT",
    image: point,
    description: "Play 1. e4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "STRATEGIST",
    image: strat,
    description: "Play 1. d4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_d4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "HUGH GRANT",
    image: hugh,
    description: "Play 1. c4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "THE HORSE WHISPERER",
    image: horse,
    description: "Play 1. Nf3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf3;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "SANGRIA",
    image: sangria,
    description: "Play the Spanish",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_spanish;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: 'THE "GAMBIT"',
    image: queen,
    description: "Play the Queen's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_qg;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "BABY STEPS",
    image: baby,
    description: "Win a game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 0;
    },
    lvlReq: 1,
    xp: 30,
    permanent: true,
  },
  {
    title: "GROWING",
    image: growing,
    description: "Win 10 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 9;
    },
    lvlReq: 2,
    xp: 70,
    permanent: true,
  },
  {
    title: "GETTING THERE",
    image: gettingthere,
    description: "Win 30 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 29;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "NOW WE'RE TALKING",
    image: talking,
    description: "Win 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 99;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "GOOD LUCK",
    image: cross,
    description: "Play Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_magnus;
    },
    lvlReq: 1,
    xp: 80,
    permanent: true,
  },
  {
    title: "GANDALF",
    image: gandalf,
    description: "Play Andrew Tang (penguingm1)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_penguin;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "MEOW",
    image: pusheen,
    description: "Play PusheenMeow",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_pusheen;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "KEEPER",
    image: keeper,
    description: "Keep at least 13 pieces (and win)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken < 4;
    },
    lvlReq: 3,
    xp: 250,
    permanent: true,
  },
  {
    title: "STREAK",
    image: two,
    description: "Win 2 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 1;
    },
    lvlReq: 2,
    xp: 60,
    permanent: true,
  },
  {
    title: "STREEAK",
    image: three,
    description: "Win 3 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 2;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "STREEEAK",
    image: four,
    description: "Win 4 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 3;
    },
    lvlReq: 5,
    xp: 400,
    permanent: true,
  },
  {
    title: "STREEEEAK",
    image: five,
    description: "Win 5 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 4;
    },
    lvlReq: 7,
    xp: 600,
    permanent: true,
  },
  {
    title: "ADOPTION",
    image: stroller,
    description: "Win 10 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 9;
    },
    lvlReq: 10,
    xp: 1000,
    permanent: true,
  },
  {
    title: "TWINS",
    image: twins,
    description: "Win 20 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 19;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "GG",
    image: gg,
    description: "Play 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_g4;
    },
    xp: 80,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "...AND BACK AGAIN",
    image: walk_flipped,
    description:
      "Reach the starting rank after reaching the other side of the board with your King (and win)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.king_reached_first_row;
    },
    xp: 240,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "THERE...",
    image: walk,
    description: "Reach the other side of the board with your King (and win)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.king_reached_last_row;
    },
    xp: 210,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "ORANGUTANG",
    image: orangutan,
    description: "Play 1. b3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_b4;
    },
    xp: 80,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "33%",
    image: gaussian,
    description: "Finish within the top third",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer) {
      return alle_turneringer.some((turnering) => {
        const players = turnering.players;

        const numPlayers = players.length;

        const place = players.indexOf(player.username);

        return place !== -1 && place < numPlayers / 3;
      });
    },
    xp: 200,
    lvlReq: 3,
    category: "tournament",
    permanent: false,
  },
  {
    title: "SPEED",
    image: speed,
    description:
      "Spend less than 2 seconds per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 2;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "50%",
    image: gaussian,
    description: "Finish within the top half",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer) {
      return alle_turneringer.some((turnering) => {
        const players = turnering.players;

        const numPlayers = players.length;

        const place = players.indexOf(player.username);

        return place !== -1 && place < numPlayers / 2;
      });
    },
    xp: 150,
    lvlReq: 3,
    category: "tournament",
    permanent: false,
  },
  {
    title: "DANGER",
    image: danger,
    description: "10 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premove_streak > 9;
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "SPEED 2",
    image: speed,
    description:
      "Spend less than one second per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 1;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "10%",
    image: gaussian,
    description: "Finish within the top tenth",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer) {
      return alle_turneringer.some((turnering) => {
        const players = turnering.players;

        const numPlayers = players.length;

        const place = players.indexOf(player.username);

        return place !== -1 && place < numPlayers / 10;
      });
    },
    xp: 300,
    lvlReq: 3,
    category: "tournament",
    permanent: false,
  },
  {
    title: "BENT",
    image: bent,
    description: "Play 1. b3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_b3;
    },
    xp: 60,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "FISCHER",
    image: fischer,
    description: "Vinn en Fischersjakkturnering.",
    secret: false,
    fr_specific: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "chess960" && t.rank === 1
      );
    },
    xp: 300,
    lvlReq: 3,
    permanent: false,
  },
  {
    title: "RISKY",
    image: risk,
    description: "5 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premove_streak > 4;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "PRINCESS",
    image: wim,
    description: "Beat a WIM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_wim;
    },
    lvlReq: 7,
    xp: 600,
    permanent: true,
  },
  {
    title: "WFM BEATER",
    image: wfm,
    description: "Beat a WFM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_wfm;
    },
    lvlReq: 7,
    xp: 400,
    permanent: true,
  },
  {
    title: "COMEBACK",
    image: recovery,
    description: "Win a game after being down 600 centipawns",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.greatest_comeback >= 600;
    },
    xp: 500,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "ROCK SOLID",
    image: stone,
    description: "Play a game with an average centipawn loss of less than 100",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.lowest_cp_loss < 100;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "GRANITE SOLID",
    image: granite,
    description: "Play a game with an average centipawn loss of less than 50",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.lowest_cp_loss < 50;
    },
    xp: 700,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "DIAMOND SOLID",
    image: diamond,
    description: "Play a game with an average centipawn loss of less than 20",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.lowest_cp_loss < 20;
    },
    xp: 1000,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "SAVED BY THE BELL",
    image: alarm,
    description: "Flag your opponent when your opponent has a forced mate",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return (player.saved_bell = true);
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "RISE FROM THE DEAD",
    image: zombie,
    description:
      "Win a game by mate when your opponent had a forced mate earlier",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return (player.rise_from_dead = true);
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "STRONK",
    image: strong,
    description: "Play a game without blunders (-300 cp moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_worst_move <= 300;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "STRONKER",
    image: stronger,
    description: "Play a game without mistakes (-150 cp moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_worst_move <= 150;
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "STRONKEST",
    image: strongest,
    description: "Play a game without inaccuracies (-50 cp moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    requires_analysis: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_worst_move <= 50;
    },
    xp: 1000,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
];
