import React from "react";

import "../../App.scss";

function AchievementBadge({
  achievement,
  player,
  alle_turneringer,
  cumScores,
  level,
  achievement_page,
}) {
  const achieved = achievement_page
    ? true
    : player && alle_turneringer && cumScores
    ? achievement.check(player, alle_turneringer, cumScores)
    : false;

  const hemmelig = achievement.secret && !achieved;

  const titleClass = achieved
    ? "achievement-title achieved"
    : "achievement-title";

  const elementClass = achievement.requires_analysis
    ? "achievement-element engine"
    : "achievement-element";

  return (
    <div key={achievement.title} className={elementClass}>
      <div className="xptittel">
        <p className="xp">XP: {achievement.xp}</p>
        <p className={titleClass}>{!hemmelig ? achievement.title : "?"}</p>
      </div>
      <img
        src={achievement.image}
        className={achieved ? "jepp" : "nope"}
        height="64px"
        alt="achievement"
      ></img>
      <p className="achievement-description">
        {!hemmelig ? achievement.description : "Secret until achieved!"}
      </p>
    </div>
  );
}

export default AchievementBadge;
